import * as React from "react";
import Image, { GatsbyImageFluidProps } from "gatsby-image";

interface BlogCardProps {
  imageProps: GatsbyImageFluidProps;
  title: string;
  tag: string;
}

export const BlogCard = ({ imageProps, title, tag }: BlogCardProps) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(241, 243, 245)",
        paddingBottom: "30px",
        height: "100%"
      }}
    >
      <Image {...imageProps} style={{ height: "80%" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
          marginTop: "10px"
        }}
      >
        <span
          style={{
            textTransform: "uppercase",
            letterSpacing: "0.75px",
            fontWeight: 500,
            fontSize: "10",
            display: "block",
            color: "rgb(147, 161, 176)"
          }}
        >
          {tag}
        </span>
        <p
          style={{
            textAlign: "center",
            width: "80%",
            fontSize: "1.2em",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};
