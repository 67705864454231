import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

import { Page } from "../components/Page";
import { ContentfulListResponse } from "../utils/queryTypes";
import { BlogCard } from "../components/BlogCard";
import { generatePostPath, getGridStyles } from "../utils/utils";

interface Response {
  id: string;
  title: string;
  tag: string;
  thumbnail: {
    fluid: {
      src: string;
      srcSet: string;
      srcSetWebp: string;
      srcWebp: string;
      aspectRatio: number;
      sizes: string;
    };
  };
}

export default () => {
  const blogPosts = useStaticQuery<
    ContentfulListResponse<"allContentfulBlogPosts", Response>
  >(graphql`
    query {
      allContentfulBlogPosts(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            id
            title
            tag
            thumbnail {
              fluid(quality: 50, maxWidth: 1000) {
                src
                srcSet
                srcWebp
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Page>
      <div style={{ minHeight: 40 }}></div>
      <div style={getGridStyles(isTabletOrMobile)}>
        {blogPosts.allContentfulBlogPosts.edges.map((post) => {
          const { title, tag, thumbnail } = post.node;
          return (
            <motion.div
              style={{
                borderRadius: "4px",
                overflow: "hidden"
              }}
              whileHover={{
                boxShadow: "0px 0px 20px"
              }}
              key={post.node.id}
            >
              <Link
                to={generatePostPath(title)}
                style={{ textDecoration: "none" }}
              >
                <BlogCard tag={tag} title={title} imageProps={thumbnail} />
              </Link>
            </motion.div>
          );
        })}
      </div>
    </Page>
  );
};
